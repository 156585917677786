.copy-item {
  &__text {
    text-decoration: underline;
    cursor: pointer;
  }

  &__notification {
    color: #838383;

    &--right {
      padding-left: 8px;
    }

    &--bottom {
      padding-top: 8px;
    }
  }

  &--right {
    display: flex;
  }

  &--bottom {
    display: grid;
  }
}
