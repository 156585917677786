.sort-menu {
  flex-shrink: 0;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__box {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    font-size: 10px;
    color: #000;
  }

  &__unselected {
    color: var(--color-white);
  }

  &__button {
    display: block;
    margin: 0;
    border: 1px solid var(--filter-color-gray);
    border-radius: 12px;
    padding: 8px 32px;
    min-width: 137px;
    max-height: 40px;
    box-sizing: border-box;
    font-family: inherit;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    background-color: var(--color-white);
    cursor: pointer;
  }
}
