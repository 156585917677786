.employee-item {
  $this: &;

  padding: 8px;

  &__inner {
    display: grid;
    border: 1px solid var(--filter-color-gray);
    border-radius: 10px;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    background-color: var(--color-white);
    gap: 30px;
    grid-template-columns: repeat(3, 1fr) auto 100px;
  }

  &__name {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 1.4;
  }

  &__corporate-email {
    font-size: 16px;
  }

  &__circle {
    display: inline-block;
    margin-right: 16px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  &__contacts-list {
    margin-top: 16px;
  }

  &__contacts-item {
    display: flex;
    align-items: center;

    & + & {
      margin-top: 6px;
    }
  }

  &__net-salary {
    display: flex;
    justify-content: space-between;
  }

  &__salary-list {
    margin-top: 24px;
  }

  &__salary-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #838383;

    & + & {
      margin-top: 6px;
    }
  }

  &__salary-label {
    margin-right: 16px;
  }

  &__official-documents-item {
    display: flex;
    flex-direction: column;

    & + & {
      margin-top: 6px;
    }
  }

  &__button {
    display: block;
    margin: 0;
    border: 1px solid var(--filter-color-gray);
    border-radius: 12px;
    padding: 8px 32px;
    min-width: 120px;
    min-width: 0;
    max-height: 40px;
    font-family: inherit;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.15;
    text-align: center;
    background-color: var(--color-white);
    cursor: pointer;
  }

  &--is-blank {
    #{$this}__inner {
      border-left: 8px solid #aaa;
    }
  }

  &--half-width {
    width: 50%;

    #{$this}__inner {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
