.analytics-page-table {
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &__total {
    font-weight: 500;
  }

  &__select {
    outline: none;
    border: none;
    width: 100%;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    background-color: transparent;
  }

  &__button {
    @include tc-button-reset;
  }

  &__checkbox {
    display: flex;
    align-items: center;
  }
}
