.search-bar-input {
  display: block;
  border: 1px solid var(--filter-color-gray);
  border-radius: 12px;
  padding: 10px 32px 10px 16px;
  width: 100%;
  max-height: 40px;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  background-color: var(--color-white);
}
