$color-light-grey: #fafafa;

// z-index
$profile-nav: 10; // 1
$sidebar-overlay: 10; // 1
$sidebar-item: 20; // 2
$sidebar-tooltip: 30; // 5
$sidebar-inner: 40; // 10
$content-card: 50; // 20
$sidebar-setting-control: 60; // 50
$template-sidebar: 70; // 90
$template-main: 80; // 100
$mobile-control: 80; // 100
$actions-block-overlay: 90; // 2000
