html.is-sidebar-mobile-opened {
  overflow: hidden;

  @include desktop {
    overflow: auto;
  }
}

.sidebar {
  $this: &;

  position: relative;
  padding: 0;
  padding-bottom: var(--mobile-controls-panel-height);
  width: var(--sidebar-width);
  height: 100%;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  will-change: width;

  &__overlay {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: $sidebar-overlay;
    display: none;
    width: 100vw;
    height: 100%;
    background-color: rgb(189 196 198 / 25%);
    opacity: 0;

    @include desktop {
      display: none;
    }
  }

  &__toggler {
    display: none;
    outline: none;
    border: none;
    padding: 7px 9px;
    width: 100%;
    box-shadow: none;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;

    svg {
      width: 24px;
      height: 26px;
    }

    .sidebar-item:hover {
      background-color: transparent;
    }

    &:focus {
      outline: none;
    }

    @include desktop {
      display: block;
    }
  }

  &__inner {
    position: relative;
    z-index: $sidebar-inner;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-image: var(--sidebar-background-gradient);
    background-color: var(--color-white);
  }

  &__top {
    position: relative;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      left: 12px;
      right: 12px;
      width: calc(100% - 24px);
      height: 1px;
      background-color: var(--color-white);
    }
  }

  &__collapse-button {
    .sidebar-item__icon {
      width: 32px;
      height: 32px;
    }
  }

  &__navlist {
    display: flex;
    align-items: flex-start;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;

    /* Hide scrollbar */
    -ms-overflow-style: none;
    overscroll-behavior: contain;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__navsection {
    position: relative;
    padding: 8px;
    width: 100%;

    @include desktop-xl {
      padding: 16px 8px;
    }
  }

  &__navitem {
    margin-bottom: 8px;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__bottom {
    position: relative;
    padding: 8px 8px 16px;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      left: 12px;
      right: 12px;
      width: calc(100% - 24px);
      height: 1px;
      background-color: var(--color-white);
    }

    @include desktop-xl {
      padding: 16px 8px 24px;
    }
  }

  &--mobile-opened {
    transform: translate3d(0, 0, 0);

    #{$this}__inner {
      box-shadow: 1px 2px 10px rgb(34 49 59 / 10%);
    }

    #{$this}__overlay {
      display: block;
      animation: show-overlay 0.2s ease-out 0.5s forwards;
    }

    @include desktop-xl {
      #{$this}__overlay {
        display: none;
      }
    }
  }

  @include tablet-xl {
    font-size: 20px;
    line-height: 1.4;
  }

  @include desktop {
    border-radius: 6px;
    padding: 0;
    width: var(--sidebar-width);
    transform: translate3d(0, 0, 0);
    transition: width 0.3s ease;

    &--collapsed {
      width: var(--sidebar-collapsed-width);
    }
  }
}

@keyframes show-overlay {
  100% {
    opacity: 1;
  }
}
