.template {
  font-size: 16px;
  line-height: 1.17;
  color: var(--color-text);
  background-color: $color-light-grey;

  &__sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: $template-sidebar;
    padding: 0;
    width: 0;

    @include desktop {
      width: auto;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 50px); /* ie11 fallback */
    min-height: calc(var(--vh, 1vh) * 100 - 50px);

    @include desktop {
      min-height: 100vh; /* ie11 fallback */
      min-height: calc(var(--vh, 1vh) * 100);
    }
  }

  &__content {
    flex: 1 1 auto;
    padding-right: 40px;
    padding-bottom: var(--mobile-controls-panel-height);
    padding-left: 40px;
    width: 100%;

    @include desktop {
      padding-bottom: 0;
    }
  }

  &__logout {
    margin-top: 16px;
  }

  &__panel {
    margin: 10px 0;
    padding: 8px 16px;
    width: 100%;
    min-height: 48px;
    box-shadow: 0 0 43px rgb(24 14 54 / 10%);
    background-color: var(--color-white);

    &--top {
      margin-top: 0;
    }

    &--bottom {
      display: none;
      margin-bottom: 0;
      text-align: right;
    }

    @include tablet {
      margin: 20px 0;
      padding: 16px;
      min-height: 64px;

      &--top {
        margin-top: 0;
      }

      &--bottom {
        margin-bottom: 0;
      }
    }

    @include desktop {
      padding: 16px 24px;

      &--bottom {
        display: block;
      }
    }
  }

  @include tablet {
    font-size: 18px;
  }

  @include desktop {
    padding-bottom: 0;
    padding-left: 320px; /* ie11 fallback */
    padding-left: calc(var(--sidebar-width));
    transition: padding-left 0.3s ease;
    will-change: padding-left;

    &--sidebar-collapsed {
      padding-left: 86px; /* ie11 fallback */
      padding-left: calc(var(--sidebar-collapsed-width));
    }
  }
}

/* actions block overlay should be over sidebar */
.is-actions-overlay-opened .template__main {
  @include desktop {
    position: relative;
    z-index: $template-main;
  }
}
