.sidebar-settings-control {
  margin-top: 16px;

  &__dropdown {
    position: absolute;
    left: calc(20px + var(--mobile-controls-panel-height));
    right: 20px;
    bottom: 110px;
    z-index: $sidebar-setting-control;

    @include tablet {
      left: 100%;
      right: auto;
      bottom: calc(10px + var(--mobile-controls-panel-height));
      padding: 0 6px;
      width: 250px;
    }

    @include desktop {
      bottom: 0;
    }
  }
}
