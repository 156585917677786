.sidebar-infobox {
  display: flex;
  align-items: center;
  padding: 8px 16px 24px;
  white-space: nowrap;

  &__image {
    display: flex;
    align-items: center;
    flex: none;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: var(--color-white);

    img {
      display: block;
      flex: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include tablet-xl {
      width: 40px;
      height: 40px;
    }
  }

  &__col {
    overflow: hidden;
    margin-left: 16px;
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  }

  &__name {
    overflow: hidden;
    font-weight: inherit;
    font-size: 16px;
    text-overflow: ellipsis;
  }

  &__email {
    overflow: hidden;
    margin-top: 3px;
    font-size: 16px;
    text-overflow: ellipsis;
  }

  @include desktop-xl {
    padding: 0 16px 24px;
  }
}

.sidebar--collapsed .sidebar-infobox__col {
  @include desktop {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
}
