$fontSize: 20px;
$fontSizeMobile: 16px;

.indicator-component {
  border-radius: 10px;
  padding: 25px 32px;
  box-shadow: 0 0 43px rgb(24 14 54 / 20%);
  background: #fff;

  &__head {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: $fontSize;
  }

  &__values > div:nth-last-child(n+2) {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 970px) {
    &__head {
      font-size: $fontSizeMobile;
    }
  }
}

.lite-box {
  border-radius: 20px;
  box-shadow: 0 0 107.5px rgb(24 14 54 / 10%);
}
