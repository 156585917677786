.theme-color-picker {
  display: flex;
  align-items: center;

  &__button {
    margin-right: 12px;
    outline: none;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-shadow: none;
    cursor: pointer;
    transition: transform;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
