.breadcrumbs {
  display: flex;
  overflow-x: scroll;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  white-space: nowrap;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* chrome */
  }

  &__breadcrumb {
    border-radius: 10px;
    padding: 4px 8px;

    &--located {
      box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
      background-color: rgb(224 223 246 / 50%);
    }

    @include desktop-xl {
      padding: 8px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin: 0 6px;
    width: 6px;
    height: 12px;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    @include desktop-xl {
      width: 10px;
      height: 16px;
    }
  }

  &__link {
    display: inline-block;
    padding: 4px 8px;
    font-size: inherit;
    color: #000;
    transition: all 0.3s ease-in-out;
    text-decoration-thickness: 0.5px;

    &:hover {
      border-radius: 10px;
      box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
      text-decoration-line: underline;
      background-color: rgb(224 223 246 / 50%);
    }
  }

  @include tablet-xl {
    font-size: 20px;
    line-height: 1.2;
  }
}
