.mobile-controls-panel {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $mobile-control;
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: var(--mobile-controls-panel-height);
  box-shadow: 1px 2px 10px rgb(34 49 59 / 10%);
  font-family: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  background-image: var(--sidebar-background-gradient);
  background-color: var(--color-white);

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 0 auto;
    outline: none;
    border: none;
    padding: 0;
    width: 32px;
    height: 32px;
    box-shadow: none;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-decoration: none;
    color: inherit;
    background-color: transparent;

    &:first-child {
      margin-left: 0;
    }
  }

  &__icon-location {
    display: block;
    flex: none;
    width: 32px;
    height: 32px;

    svg {
      width: 100%;
      height: 100%;
    }

    @include tablet-xl {
      width: 40px;
      height: 40px;
    }
  }

  &__item-icon {
    display: block;
    flex: none;
    width: 16px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;
    }

    @include tablet-xl {
      width: 32px;
      height: 32px;
    }
  }

  @include tablet {
    padding: 0 26px;
    font-size: 15px;
  }

  @include desktop {
    display: none;
  }
}
