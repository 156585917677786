.copyright {
  display: inline-block;

  &__link {
    text-decoration: none;
    color: var(--color-copyright-link);

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
