.info-tip {
  position: relative;
  z-index: 10;
  flex: none;
  margin-right: 4px;
  width: 32px;
  height: 32px;

  &__toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: none;
    border-radius: 50%;
    padding: 0;
    width: 100%;
    height: 100%;
    box-shadow: none;
    font-size: 18px;
    color: inherit;
    background-color: transparent;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      left: 4px;
      top: 4px;
      z-index: 1;
      border-radius: 50%;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
    }

    svg {
      flex: none;
      color: inherit;
    }

    &:hover,
    &:focus {
      outline: none;
      opacity: 0.8;
    }
  }

  &__content {
    position: absolute;
    left: -6px;
    top: calc(100% + 10px);
    display: none;
    border-radius: 6px;
    padding: 12px 20px;
    min-width: 180px;
    box-shadow: 1px 2px 10px rgb(34 49 59 / 10%);
    box-shadow: 0 4px 14px rgb(0 0 0 / 15%);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    text-align: start;
    color: var(--color-text-tooltip);
    background-color: var(--color-white);
    animation: messageAnim 0.1s ease-out forwards;

    &::before {
      content: "";
      position: absolute;
      left: 10px;
      bottom: 100%;
      border-style: solid;
      border-width: 0 7px 7px;
      border-color: transparent transparent var(--color-white);
      width: 0;
      height: 0;
    }
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  &--opened {
    z-index: 20;
  }
}

.info-tip--opened .info-tip__content {
  display: block;
}

@keyframes messageAnim {
  from {
    opacity: 0;
    transform: translate3d(0, -4px, 0);
  }
}
