.sidebar-settings-menu {
  border-radius: 6px;
  padding: 15px;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  background-color: var(--color-white);
  animation: menu-show 0.2s ease-out forwards;

  &__item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
  }
}

@keyframes menu-show {
  from {
    opacity: 0;
  }
}
