.sidebar-logo {
  display: flex;
  padding: 10px 16px 16px 18px;
  text-decoration: none;
  color: inherit;

  &__image {
    display: block;
    flex: none;
    width: 32px;
    height: 44px;
  }

  &__text-container {
    position: relative;
    margin-left: 12px;
    height: 44px;
  }

  &__text {
    position: absolute;
    left: 0;
    top: 2px;
    height: 100%;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  @include desktop-xl {
    padding: 16px 20px 24px;
  }
}

@include desktop {
  .sidebar--collapsed .sidebar-logo__text {
    opacity: 0;
    transform: translate3d(-16px, 0, 0);
  }
}
