.employee-edit {
  margin: 0 auto;
  max-width: 600px;

  &__title {
    text-align: center;
  }

  &__info {
    display: flex;
    align-items: center;
    font-weight: 500;

    & + & {
      margin-top: 24px;
    }
  }

  &__icon {
    display: inline-block;
    margin-right: 20px;
    width: 40px;
    height: 40px;
  }

  &__item {
    display: flex;
    align-items: center;

    &--radio-list {
      align-items: start;
    }

    & + & {
      margin-top: 44px;
    }
  }

  &__label {
    display: block;
    width: 40%;
  }

  &__control {
    width: auto;
  }

  &__git {
    display: flex;
    align-items: center;
  }

  &__symbol {
    margin-right: 4px;
  }

  &__box-buttons {
    margin-top: 40px;
    margin-bottom: 32px;
    text-align: center;

    button + button {
      margin-left: 24px;
    }
  }

  &__button {
    border: 1px solid var(--filter-color-gray);
    border-radius: 12px;
    padding: 8px 32px;
    font-family: inherit;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.15;
    background-color: var(--color-white);
    cursor: pointer;
  }
}
