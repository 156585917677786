:root {
  --sidebar-width: 207px;
  --sidebar-collapsed-width: 72px;
  --mobile-controls-panel-height: 56px;
  --color-white: #fff;
  --color-text: #172f3d;
  --color-grey: #c2c2c2;
  --color-accent-first: #0c8bb4;
  --color-accent-last: #f53893;
  --color-link: var(--color-accent-first);
  --color-copyright-link: var(--color-accent-last);
  --accent-gradient-color-1: #fff0fc;
  --accent-gradient-color-2: #eceaff;
  --accent-gradient-color-3: #e1f1ff;
  --auth-bg-accent-color-first: #81b4ff;
  --auth-bg-accent-color-last: #ffc1ee;
  --sidebar-background-gradient: linear-gradient(0deg, rgb(224 223 246 / 50%), rgb(224 223 246 / 50%));
  --filter-color-light-gray: #f1f5f7;
  --filter-color-gray: #e4ecf0;

  @include tablet-xl {
    --sidebar-width: 256px;
    --mobile-controls-panel-height: 80px;
  }

  @include desktop-xl {
    --sidebar-width: 318px;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, Rubik, sans-serif;
  font-weight: 300;
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.is-hidden {
  display: none;
}

a {
  color: inherit;
}
